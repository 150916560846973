import { useRouter } from 'next/router';
import Error from '../../components/Error';
import errors from '../../errors.json';
import SimplePage from '../../layouts/SingleBlockPage/SimplePage';
import React, { FunctionComponent } from 'react';

export interface ErrorProps {
    code: string | number;
}

const ErrorPage: FunctionComponent<ErrorProps> = ({ code }) => {
    const router = useRouter();
    const errorCode = String((code
        ? code
        : router.query.slug instanceof Array
            ? router.query.slug[0]
            : router.query.slug
    ) || 500);
    const error = (errors as any)[errorCode] ||
        { h1: 'HTTP 500 - Internal Server Error' }
    ;

    return <SimplePage
        title={ error.h1 }
        withActions={ false }
        fullPaperWidth={ true }
    >
        <Error code={ errorCode } />
    </SimplePage>;
};

export default ErrorPage;
