import Box from '@mui/material/Box';
import { Theme } from '@mui/material/styles';
import HomePaperPad from '../PaperPad';
import Section from './Section';
import { ContentData } from './types/Content';
import ContactUsForm from './sections/Content/ContactUsForm';
import { KnownBreakpoints } from '../../common/constants';

const styles = {
    content: {
        width: '100%',
    },
    paper: (theme: Theme) => ({
        padding: '60px 40px 40px',
        marginBottom: '40px',
        [theme.breakpoints.down(KnownBreakpoints.tablet)]: {
            padding: '42px 20px',
        },
    }),
    lastRevisited: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginBottom: '20px',
        fontStyle: 'italic',
    },
};

type ContentProps = { data: ContentData[], slug: string };

// TODO: rewrite Content and Header as Generic component <SectionIterator />
const Content: React.FunctionComponent<
    ContentProps
> = ({ data, slug }) => {
    const isContactUs = slug === 'contact-us';

    return <Box sx={ styles.content }>
        { data.map((item, idx) => {
            return <HomePaperPad key={ idx } sx={ styles.paper }>
                { (item as any).lastRevised && <Box sx={ styles.lastRevisited }>
                    Last revised: { (item as any).lastRevised }
                </Box> }
                <Section data={ item } />
            </HomePaperPad>;
        }) }
        { isContactUs &&
            <HomePaperPad sx={ styles.paper }>
                <ContactUsForm/>
            </HomePaperPad>
        }
    </Box>;
};

export default Content;
