import Box from '@mui/material/Box';
import { Theme } from '@mui/material/styles';
import { KnownBreakpoints } from '../../common/constants';
import { CONTENT_MARGIN_TOP } from '../../config';
import Content from './Content';
import Sidebar from './Sidebar';
import { ContentData } from './types/Content';
import { SidebarData } from './types/Sidebar';

const styles = {
    wrapper: (theme: Theme) => ({
        width: '100%',
        display: 'flex',
        gap: '20px',
        marginTop: `-${ CONTENT_MARGIN_TOP }px`,
        paddingBottom: '60px',
        [theme.breakpoints.down(KnownBreakpoints.laptop)]: {
            flexDirection: 'column',
        },
    }),
    content: {
        width: '100%',
        height: '100%',
    },
};

type WrapperProps = {
    content: ContentData[];
    sidebar?: SidebarData[] | null;
    slug: string;
};

const Wrapper: React.FunctionComponent<WrapperProps> = props => {
    return <Box sx={ styles.wrapper }>
        <Box sx={ styles.content }>
            <Content data={ props.content } slug={ props.slug } />
        </Box>
        { props.sidebar && <Sidebar data={ props.sidebar } /> }
    </Box>;
};

export default Wrapper;
