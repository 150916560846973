import React from 'react';
import Box from '@mui/material/Box';
import { Theme, useTheme } from '@mui/material/styles';
import { KnownBreakpoints } from '../../common/constants';
import Section from './Section';
import { SidebarData } from './types/Sidebar';

const styles = {
    sidebar: (theme: Theme, isBlog: boolean) => ({
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
        maxWidth: isBlog ? '325px' : '100%',
        [theme.breakpoints.down(KnownBreakpoints.laptop)]: {
            maxWidth: '100%',
            flexDirection: 'row',
            flexWrap: 'wrap',
        },
    }),
};

type SidebarProps = { data: SidebarData[] };

const Sidebar: React.FunctionComponent<SidebarProps> = ({ data }) => {
    const theme = useTheme<Theme>();
    const isBlog = data.length > 1;

    return <Box sx={ styles.sidebar(theme, isBlog) }>
        { data.map((item, idx) => {
            return <Section key={ idx } data={ item } />;
        }) }
    </Box>;
};

export default Sidebar;
