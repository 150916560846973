import { FunctionComponent } from 'react';
import { Theme, useTheme } from '@mui/material/styles';
import { isLight } from '../../../../common/helpers';
import MailIcon from '../../../../assets/SvgIcons/MailIcon';
import Box from '@mui/material/Box';

const styles = {
    wrapper: (theme: Theme) => ({
        width: '36px',
        height: '36px',
        borderRadius: '50px',
        backgroundColor: isLight(theme)
            ? theme.palette.primary.main
            : theme.palette.primary.dark,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    }),
};

const ContactUsIcon: FunctionComponent = () => {
    const theme = useTheme<Theme>();

    return <Box sx={ styles.wrapper }>
        <MailIcon
            sx={{ fontSize: '16px' }}
            fill={ theme.palette.common.white }
        />
    </Box>;
};

export default ContactUsIcon;
