import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Theme, useTheme } from '@mui/material/styles';
import ContactUsIcon from './ContactUsIcon';
import { useConfig } from '../../../../common/hooks/ConfigProvider';
import Grid from '@mui/material/Grid';
import EmailField from '../../../Form/EmailField';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import {
    LOCALE,
    RECAPTCHA_CONTACT_US,
    STATES,
} from '../../../../config/membersArea';
import MenuItem from '@mui/material/MenuItem';
import { KnownBreakpoints } from '../../../../common/constants';
import Button from '@mui/material/Button';
import {
    ApiClient,
    isOffline,
    genRecaptchaSessionId,
} from '../../../../common/helpers';
import { getKeyByValue } from '../../../../common/helpers/getKeyByValue';
import Alert from '@mui/material/Alert';
import WarningIcon from '@mui/icons-material/Warning';
import { RX_EMAIL } from '../../../../common/helpers/validation';
import useAuthorized from '../../../../common/hooks/useAuthorized';
import { ReCaptcha } from '../../../ReCaptcha';
import BlockTitle from '../../components/BlockTitle';
import MaskedTextField from '../../../MaskedTextField';
import OfflineError from '../../../OfflineError';
import asyncLogger from '../../../../common/logger';
import SmartLink from '../../../SmartLink';
import { HEADER_BOTTOM_HEIGHT } from '../../../../config';

const styles = {
    container: (theme: Theme) => ({
        paddingRight: '77px',
        [theme.breakpoints.down(KnownBreakpoints.tablet)]: {
            paddingRight: 'unset',
        },
    }),
    titleContainer: {
        display: 'flex',
        alignItems: 'center',
        gap: '10px',
        marginBottom: '20px',
    },
    titleText: (theme: Theme) => ({
        ...theme.typography.h4,
        color: theme.palette.text.primary,
        lineHeight: '28.13px',
    }),
    subtitleInfo: (theme: Theme, isPhoneNumber?: boolean) => ({
        fontSize: theme.typography.fontSize,
        fontWeight: isPhoneNumber ? 800 : theme.typography.fontWeightRegular,
        color: theme.palette.text.primary,
        lineHeight: '24px',
        marginBottom: '20px',
    }),
    input: {
        width: '100%',
    },
    select: (theme: Theme) => ({
        '& .MuiOutlinedInput-input' : { fontSize: theme.typography.fontSize },
    }),
    messageTitle: (theme: Theme, error: boolean) => ({
        fontSize: theme.typography.fontSize,
        fontWeight: theme.typography.fontWeightRegular,
        color: !error ? theme.palette.text.primary : theme.palette.error.main,
        lineHeight: '24px',
        marginTop: '30px',
    }),
    textArea: (theme: Theme, error: boolean) => ({
        width: '100%',
        height: '160px',
        padding: '10px',
        border: `${ !error ? '1px' : '2px' } solid ${ !error
            ? theme.palette.text.primary
            : theme.palette.error.main
        }`,
        backgroundColor: 'transparent',
        color: theme.palette.text.primary,
        fontSize: theme.typography.fontSize,
        fontWeight: theme.typography.fontWeightRegular,
        lineHeight: '24px',
        marginBottom: '30px',
    }),
    submitButton: (theme: Theme) => ({
        minWidth: '258px',
        [theme.breakpoints.down(KnownBreakpoints.tablet)]: {
            minWidth: 'unset',
            width: '100%',
        },
    }),
};

interface FormValue {
    value: string;
    error?: boolean;
}

interface FormState {
    email: FormValue;
    fullName: FormValue;
    phoneNumber: FormValue;
    state: FormValue;
    subject: FormValue;
    message: FormValue;
}

const ContactUsForm: FunctionComponent = () => {
    const { recaptchaId } = useConfig();
    const { consumerOperationsDetails: { phoneNumber }} = useConfig() as any;
    const user = useAuthorized();

    const initialState: FormState = {
        email: { value: '' },
        fullName: { value: '' },
        phoneNumber: { value: '' },
        state: { value: '' },
        subject: { value: '' },
        message: { value: '' },
    };

    const theme = useTheme<Theme>();

    const [sessionId, setSessionId] = useState(genRecaptchaSessionId());
    const [token, setToken] = useState<string>('');
    const [userLoggedIn, setUserLoggedIn] = useState(true);
    const [state, setState] = useState(initialState);
    const [emptyFieldError, setEmptyFieldError] = useState<string | undefined>(
        undefined,
    );
    const [emailError, setEmailError] = useState<string | undefined>(
        undefined,
    );
    const [phoneError, setPhoneError] = useState<string | undefined>(
        undefined,
    );
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState<string | undefined>(undefined);
    const [fail, setFail] = useState<string | undefined>(undefined);
    const [networkError, setNetworkError] = useState(false);
    const alertRef = useRef<HTMLDivElement | null>(null);

    const handleChange = (
        prop: keyof FormState,
    ) => ({ target: { value } }: any) => {
        setEmptyFieldError(undefined);
        setEmailError(undefined);
        setPhoneError(undefined);
        setSuccess(undefined);
        setFail(undefined);

        setState({
            ...state,
            [prop]: { value },
        });
    };

    const handleSubmit = async(): Promise<void> => {
        setSuccess(undefined);
        setFail(undefined);

        if (state.email.value === ''
            || state.fullName.value === ''
            || state.phoneNumber.value === ''
            || state.state.value === ''
            || state.subject.value === ''
            || state.message.value === ''
        ) {
            setEmptyFieldError('Required');

            return ;
        }

        if (!RX_EMAIL.test(state.email.value)) {
            setEmailError('Invalid Email Address');

            return ;
        }

        if (state.phoneNumber.value.includes('_')) {
            setPhoneError(() => 'Invalid phone number!');

            return;
        }

        setLoading(true);

        try {
            const convertedState = {
                ...state,
                state: { value: getKeyByValue(STATES, state.state.value) },
            };
            const { data } = await ApiClient.request<{
                data: { sendCustomerEmail: boolean }
            }>('/contactUs', {
                body: { formState: convertedState, token },
                withAuth: false,
            });

            if (data?.sendCustomerEmail === true) {
                setSuccess('Your message was received on our system. One '
                    + 'of our Support Representatives will be in touch with '
                    + 'you shortly.',
                );

                setSessionId(genRecaptchaSessionId());
                setLoading(false);

                return ;
            }
        } catch (err) {
            asyncLogger.error(err);

            if (isOffline(err)) {
                setNetworkError(true);

                setSessionId(genRecaptchaSessionId());
                setLoading(false);

                return ;
            }
        }

        setFail('We could not send your message! Please ensure that all '
            + 'data is accurate, or consider reloading the page and trying '
            + 'again!',
        );

        setSessionId(genRecaptchaSessionId());
        setLoading(false);
    };

    const handleCaptchaVerify = (token: string) => {
        setToken(token);
        setLoading(false);
    };

    useEffect(() => {
        //useUser response must be placed in state as if not, it breaks the hydration
        if (user) {
            setUserLoggedIn(true);
            setLoading(false);
        } else {
            setUserLoggedIn(false);
        }
    }, [user]);

    useEffect(() => {
        if (success && alertRef.current) {
            const elementPosition
                = alertRef.current.getBoundingClientRect().top;
            const offsetPosition
                = elementPosition + window.pageYOffset - HEADER_BOTTOM_HEIGHT;

            window.scrollTo({
                top: offsetPosition,
                behavior: 'smooth',
            });
        }
    }, [success]);

    return <Box sx={ styles.container }>
        { networkError &&
            <OfflineError onClose={ () => setNetworkError(false) } />
        }
        <Box sx={ styles.titleContainer }>
            <ContactUsIcon/>
            <BlockTitle title="Send a message" />
        </Box>
        <Typography sx={ styles.subtitleInfo }>
            { `Please allow 1 to 2 business days for us to respond to
                your message. Call us if you don't see a topic that
                fits your needs. For immediate assistance regarding
                payments or funding, please call our friendly
                customer support agents at `
            }
            <span style={ styles.subtitleInfo(theme, true) }>
                <SmartLink
                    href={ `tel:${ phoneNumber }` }
                    style={ (styles.subtitleInfo as any)(theme, true) }
                >
                    { phoneNumber + '.' }
                </SmartLink>
            </span>
        </Typography>
        { (emptyFieldError
            || emailError
            || phoneError
            || fail
        ) && !success && <Alert
            severity={ 'error' }
            iconMapping={{
                error: <WarningIcon/>,
            }}
            sx={{ paddingRight: '11px' }}
        >
            { emptyFieldError
                ? emptyFieldError
                : emailError
                    ? emailError
                    : phoneError
                        ? phoneError
                        : fail
            }
        </Alert> }
        { !!success && <Alert
            severity={ 'success' }
            sx={{ paddingRight: '11px' }}
            ref={ alertRef }
        >
            { success }
        </Alert> }
        <Grid container spacing={ 1 }>
            <Grid item mobile={ 12 } tablet={ 6 }>
                <EmailField
                    id="email-address"
                    label="Email address*"
                    sx={ styles.input }
                    inputProps={{ maxLength: 255 }}
                    onChange={ handleChange('email') }
                    error={ (!!emptyFieldError && state.email.value === '')
                        || !!emailError
                    }
                />
            </Grid>
            <Grid item mobile={ 12 } tablet={ 6 }>
                <MaskedTextField
                    mask="999-999-9999"
                    label="Phone number*"
                    sx={ styles.input }
                    inputProps={{ maxLength: 255 }}
                    onChange={ handleChange('phoneNumber') }
                    error={
                        (!!emptyFieldError
                            && state.phoneNumber.value === ''
                        ) || !!phoneError
                    }
                />
            </Grid>
            <Grid item mobile={ 12 } tablet={ 6 }>
                <TextField
                    label="Full name"
                    sx={ styles.input }
                    inputProps={{ maxLength: 255 }}
                    onChange={ handleChange('fullName') }
                    error={ !!emptyFieldError && state.fullName.value === '' }
                />
            </Grid>
            <Grid item mobile={ 12 } tablet={ 6 }>
                <FormControl fullWidth>
                    <InputLabel
                        error={ !!emptyFieldError && state.state.value === '' }
                    >
                        Select state
                    </InputLabel>
                    <Select
                        label="Select state"
                        sx={ styles.select }
                        onChange={ handleChange('state') }
                        value={ state.state.value }
                        error={ !!emptyFieldError && state.state.value === '' }
                    >
                        { Object.keys(STATES).map((
                            state: string,
                            index: number,
                        ) => (
                            <MenuItem
                                value={ STATES[state] }
                                key={ index }
                            >
                                { STATES[state] }
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item mobile={ 12 } tablet={ 12 }>
                <FormControl fullWidth>
                    <InputLabel
                        error={
                            !!emptyFieldError && state.subject.value === ''
                        }
                    >
                        Select message subject
                    </InputLabel>
                    <Select
                        label="Select message subject"
                        sx={ styles.select }
                        onChange={ handleChange('subject') }
                        value={ state.subject.value }
                        error={
                            !!emptyFieldError && state.subject.value === ''
                        }
                    >
                        <MenuItem value="Cancel my payment or loan">
                            Cancel my payment or loan
                        </MenuItem>
                        <MenuItem value="Complaint">Complaint</MenuItem>
                        <MenuItem value="Help with logging in/application">
                            Help with logging in/application
                        </MenuItem>
                        <MenuItem value="Need help with my application">
                            Need help with my application
                        </MenuItem>
                        <MenuItem value="How do I make a payment">
                            How do I make a payment
                        </MenuItem>
                        <MenuItem value="How do I update my application">
                            How do I update my application
                        </MenuItem>
                        <MenuItem value="Increase my loan amount">
                            Increase my loan amount
                        </MenuItem>
                        <MenuItem value="Past Due Account">
                            Past Due Account
                        </MenuItem>
                        <MenuItem value="Privacy and Security">
                            Privacy and Security
                        </MenuItem>
                        <MenuItem value="Referrals">Referrals</MenuItem>
                        <MenuItem value="Returned Payment">
                            Returned Payment
                        </MenuItem>
                        <MenuItem value="Status of my faxed documents">
                            Status of my faxed documents
                        </MenuItem>
                        <MenuItem value="Status of my loan or application">
                            Status of my loan or application
                        </MenuItem>
                        <MenuItem value="Other">Other</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
        </Grid>
        <Typography sx={ styles.messageTitle(
            theme,
            !!emptyFieldError && state.message.value === '',
        )}>
            Message
        </Typography>
        <textarea
            style={ styles.textArea(
                theme,
                !!emptyFieldError && state.message.value === '',
            )}
            onChange={ handleChange('message') }
        />
        <Button
            variant="contained"
            color="secondary"
            sx={ styles.submitButton }
            onClick={ handleSubmit }
            disabled={ loading }
            data-testid="btn-submit"
        >
            { !loading ? 'Submit' : 'Loading' }
        </Button>
        { !userLoggedIn &&
            <ReCaptcha
                siteKey={ recaptchaId }
                onVerify={ handleCaptchaVerify }
                language={ LOCALE }
                action={ RECAPTCHA_CONTACT_US }
                sessionId={ sessionId }
            />
        }
    </Box>;
};

export default ContactUsForm;
