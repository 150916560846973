import { FC } from 'react';
import { Box } from '@mui/material';
import Section from './Section';
import { HeaderData } from './types/Header';
import { useTheme } from '@mui/material/styles';
import { KnownBreakpoints } from '../../common/constants';

type HeaderProps = { data: HeaderData[] };

// TODO: rewrite as Generic component <SectionIterator />
const Header: FC<HeaderProps> = ({ data }) => {
    const theme = useTheme();

    return <Box sx={{
        [theme.breakpoints.down(KnownBreakpoints.tablet)]: {
            padding: '0 20px',
        },
    }}>
        { data.map((item, idx) => {
            return <Section key={ idx } data={ item } />;
        }) }
    </Box>;
};

export default Header;
